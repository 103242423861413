import { ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import InlineMessage from '@ingka/inline-message';
import Search, { SearchProps } from '@ingka/search';
import { useSearch } from './useSearch';
import { useCloseSidebar } from '../../../store/features/sidebar/sidebarSlice';

export function OTCSearchBody() {
  const { t } = useTranslation();
  const { search, setValidationError, validationError } = useSearch();
  const closeSidebar = useCloseSidebar();

  const clearValidationErrorOnInputChange = () => {
    if (validationError) {
      setValidationError('');
    }
  };

  const onSearch: SearchProps['onSearch'] = (_, data) => {
    search(data?.value);
    closeSidebar();
  };

  return (
    <ModalBody>
      <Search
        ariaLabel={t('otc-search-sidebar.ariaLabel')}
        clearBtnText={t('otc-search-sidebar.clearBtnText')}
        id="otc-search-sidebar-input"
        onChange={clearValidationErrorOnInputChange}
        onClear={clearValidationErrorOnInputChange}
        onSearch={onSearch}
        placeholder={t('otc-search-sidebar.placeholder')}
        submitBtnText={t('otc-search-sidebar.submitBtnText')}
      />
      {!!validationError && (
        <InlineMessage
          body={validationError}
          className="mt-4"
          ssrIcon={informationCircle}
          variant="negative"
        />
      )}
    </ModalBody>
  );
}
