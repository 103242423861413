import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useChangeBlindReturnItemQuantity,
  useGetOTCState,
  useUpdateItemsForPop,
} from '../../../store/features/OTCSlice/OTCSlice';
import {
  ARTICLE_REGEX_NO_DOTS,
  IN_STORE_PURCHASE_REGEX,
  ONLINE_PURCHASE_REGEX,
  removeDots,
} from '../../../store/features/OTCSlice/reducerUtils';
import { useGetReturnAuthorizationProducts } from '../../../hooks/useGetReturnAuthorizationProducts.js';
import { useGetProductInfo } from '../../../hooks/useGetProductInfo';
import { getProductFromPops } from '../../../hooks/useResolutionsScanner/utils/scannerUtils';

export const useSearch = () => {
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState('');
  const getReturnAuthorizationProducts = useGetReturnAuthorizationProducts();
  const popsInStore = useGetOTCState('pops');
  const blindReturnInState = useGetOTCState('blindReturn');
  const increaseBlindReturnItemQuantity = useChangeBlindReturnItemQuantity();
  const getProductInfo = useGetProductInfo();
  const updateItemsForPop = useUpdateItemsForPop();

  const search = (searchString: string | undefined) => {
    if (typeof searchString !== 'string') return;

    const popAlreadyAdded = popsInStore.some((statePop) => statePop.id === searchString);
    if (popAlreadyAdded) {
      setValidationError(t('otc-search-sidebar.popAlreadyAdded'));
      return;
    }

    const isPoPRegex = new RegExp(
      `${IN_STORE_PURCHASE_REGEX.source}|${ONLINE_PURCHASE_REGEX.source}`,
    );
    if (searchString.match(isPoPRegex)) {
      getReturnAuthorizationProducts(searchString);
      return;
    }

    const searchStringWithoutDots = removeDots(searchString);
    if (!searchStringWithoutDots.match(ARTICLE_REGEX_NO_DOTS)) {
      setValidationError(t('otc-search-sidebar.noMatchingSearchString'));
      return;
    }

    const productInExistingPops = getProductFromPops(popsInStore, searchStringWithoutDots);
    if (productInExistingPops) {
      updateItemsForPop(productInExistingPops);
      return;
    }

    const productInBlindReturn = blindReturnInState.items.some(
      (item) => item.itemNo === searchStringWithoutDots,
    );
    if (productInBlindReturn) {
      increaseBlindReturnItemQuantity(searchStringWithoutDots);
      return;
    }

    getProductInfo(searchStringWithoutDots);
  };

  return { search, setValidationError, validationError };
};
