import InlineMessage from '@ingka/inline-message';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { formatTimeRange, getDateLocale } from 'shared-frontend';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import {
  ExchangeResolutionOld,
  ExchangeResolutionNew,
  isOldExchangeResolution,
} from '../../models/Sac';

const getExchangeResolutionData = (
  exchangeResolution: ExchangeResolutionOld | ExchangeResolutionNew,
) => {
  const { orderNumber } = exchangeResolution;

  if (isOldExchangeResolution(exchangeResolution)) {
    const { deliveryFromDateTime, deliveryToDateTime } = exchangeResolution;
    return {
      deliveryFromDateTime: deliveryFromDateTime ?? null,
      deliveryToDateTime: deliveryToDateTime ?? null,
      orderNumber,
      tspId: null,
      tspName: null,
    };
  }

  const { timeWindow, tspData } = exchangeResolution;
  return {
    deliveryFromDateTime: timeWindow?.fromDateTime ?? null,
    deliveryToDateTime: timeWindow?.toDateTime ?? null,
    orderNumber,
    tspId: tspData?.id ?? null,
    tspName: tspData?.name ?? null,
  };
};

export function ExchangeResolution() {
  const { exchangeResolution } = useGetAppState('customerReturn') ?? {};
  const { languageCode, countryCode } = useMarketLocale();
  const { t } = useTranslation();

  if (!exchangeResolution) return null;

  const {
    deliveryFromDateTime,
    deliveryToDateTime,
    orderNumber,
    tspId,
    tspName,
  } = getExchangeResolutionData(exchangeResolution);

  const locale = getDateLocale(languageCode, countryCode);
  let deliveryDateTimeStr = '';
  if (deliveryFromDateTime && deliveryToDateTime) {
    try {
      deliveryDateTimeStr = formatTimeRange({
        endDate: new Date(deliveryToDateTime),
        locale,
        startDate: new Date(deliveryFromDateTime),
      });
    } catch (e: unknown) {
      const errorMessage = `formatTimeRange failed with deliveryToDateTime="${deliveryToDateTime}" and deliveryFromDateTime="${deliveryFromDateTime}"`;
      const error = new Error(errorMessage, { cause: e });
      error.name = 'ExchangeResolution component failed to render';
      throw error;
    }
  }

  const tspString = (tspId && tspName) ? t('exchangeResolution.tsp', { tspName, tspId }) : '';

  return (
    <InlineMessage
      body={(
        <>
          <Text tagName="span" className="block">{t('exchangeResolution.description')}</Text>
          <Text tagName="span" className="block !text-dark font-bold mt-4">
            {t('exchangeResolution.additionalInfo')}
          </Text>
          <Text tagName="span" className="block">
            {t('exchangeResolution.outgoingOrder', { orderNumber })}
          </Text>
          {deliveryDateTimeStr && (
            <Text data-testid="delivery-date-time" tagName="span" className="block">
              {t('exchangeResolution.deliveryDate', { deliveryDateTimeStr })}
            </Text>
          )}
          {tspString && (
            <Text data-testid="tsp-data" tagName="span" className="block">
              {tspString}
            </Text>
          )}
        </>
      )}
      className="my-4 m:w-fit"
      data-testid="exchange-resolution"
      title={t('exchangeResolution.title')}
      variant="cautionary"
      headingLevel="2"
    />
  );
}
