import Text from '@ingka/text';

export function TextLabel({ text }: { text: string }) {
  return (
    <Text
      bodySize="m"
      tagName="h3"
      className="flex justify-between text-dark font-bold mb-3"
    >
      {text}
    </Text>
  );
}
