import { useTranslation } from 'react-i18next';
import TextArea from '@ingka/text-area';
import { useSetArticleIssueComment } from '../../../store/features/OTCSlice/OTCSlice';
import { PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { useOnUnmount } from '../../../hooks/useOnUnmount/useOnUnmount';
import { TextLabel } from './TextLabel';
import { ArticleIssue } from '../../ArticleIssue/ArticleIssue';

export function IssueGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  const setIssue = useSetArticleIssueComment();

  const [comment, setComment] = useOnUnmount({
    initialValue: item.issue?.comment ?? '',
    onUnmount: (liveValue) => setIssue(liveValue, {
      popId: item.popId as string,
      itemLineId: item.itemLineId,
    }),
  });

  const showIssueComment = item?.issue?.mainReasonCode;
  return (
    <div className="w-full">
      <TextLabel text={t('otc-item-details-sidebar.issue-group.title')} />
      <ArticleIssue item={item} />
      {showIssueComment && (
        <TextArea
          data-testid="otc-item-details-note"
          id="otc-item-details-notes"
          label="Issue comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="mt-2"
        />
      )}
    </div>
  );
}
