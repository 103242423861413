import { useTranslation } from 'react-i18next';
import { PopItem } from '../../../store/features/OTCSlice/reducerUtils';
import { OTCArticleQuantity } from '../../OTCArticleTable/OTCArticleQuantity';
import { TextLabel } from './TextLabel';

export function QuantityGroup({ item }: { item: PopItem }) {
  const { t } = useTranslation();
  return (
    <div>
      <TextLabel text={t('otc-article-table.table-head-columns.quantity')} />
      <OTCArticleQuantity item={item} />
    </div>
  );
}
